import Image from 'next/image';
import React from 'react';

const Loading = () => {
  return (
    <div className="shared-loading tw-min-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center">
      <Image src={'/images/logo.png'} priority width={250} height={80} alt="logo" className="tw-object-contain" />
      <span>Loading...</span>
    </div>
  );
};

export default Loading;
