'use client';

import Contexts from '@/contexts';
import Image from 'next/image';
import React, { useContext } from 'react';

const FooterOtherWebsites = () => {
  const data = useContext(Contexts.sharedDataContext);

  if (data && (data['1'] || data['2'] || data['3'] || data['4'] || data['5'] || data['6']))
    return (
      <div
        className="footer-other-websites d-flex align-items-center justify-content-center flex-wrap mt-4 mb-0"
        style={{ gap: '20px' }}
      >
        {data['1'] && data['1'].card1 && data['1'].url1 && (
          <a href={`https://${data['1'].url1}`} target="_blank" className="d-block bg-white rounded-2">
            <Image src={data['1'].card1 as string} width={100} height={100} title={'Iranjourney'} alt="Iranjourney" />
          </a>
        )}
        {data['2'] && data['2'].card2 && data['2'].url2 && (
          <a href={`https://${data['2'].url2}`} target="_blank" className="d-block bg-white rounded-2">
            <Image src={data['2'].card2 as string} width={100} height={100} title={'visachance'} alt="visachance" />
          </a>
        )}
        {data['3'] && data['3'].card3 && data['3'].url3 && (
          <a href={`https://${data['3'].url3}`} target="_blank" className="d-block bg-white rounded-2">
            <Image src={data['3'].card3 as string} width={100} height={100} title={''} alt="" />
          </a>
        )}
        {data['4'] && data['4'].card4 && data['4'].url4 && (
          <a href={`https://${data['4'].url4}`} target="_blank" className="d-block bg-white rounded-2">
            <Image src={data['4'].card4 as string} width={100} height={100} title={''} alt="" />
          </a>
        )}
        {data['5'] && data['5'].card5 && data['5'].url5 && (
          <a href={`https://${data['5'].url5}`} target="_blank" className="d-block bg-white rounded-2">
            <Image src={data['5'].card5 as string} width={100} height={100} title={''} alt="" />
          </a>
        )}
        {data['6'] && data['6'].card6 && data['6'].url6 && (
          <a href={`https://${data['6'].url6}`} target="_blank" className="d-block bg-white rounded-2">
            <Image src={data['6'].card6 as string} width={100} height={100} title={''} alt="" />
          </a>
        )}
      </div>
    );
  else return null;
};

export default FooterOtherWebsites;
