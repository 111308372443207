'use client';

import { useTranslation } from '@/i18n/client';
import React, { useContext, useState } from 'react';
import { Locale } from '../../../i18n-config';
import Contexts from '@/contexts';
import Image from 'next/image';
import { Accordion, Button, Col, Collapse, Container, Row } from 'react-bootstrap';
import Link from 'next/link';
import { BsChevronDown, BsChevronUp, BsEnvelope, BsWhatsapp } from 'react-icons/bs';
import CopyToClipboard from '../shared/CopyToClipboard';
import FooterOtherWebsites from './FooterOtherWebsites';
import { useMediaQuery } from '@uidotdev/usehooks';
import { locales } from '@/i18n/settings';
import { usePathname } from 'next/navigation';
import { BsFacebook, BsInstagram, BsLinkedin, BsX, BsYoutube } from 'react-icons/bs';
import { FaTripadvisor } from 'react-icons/fa';

const Footer = ({ language }: { language: Locale }) => {
  const pathName = usePathname();
  const { t, i18n } = useTranslation(language);

  const generalData = useContext(Contexts.sharedDataContext);
  const isMobile = useMediaQuery('only screen and (max-width : 768px)');

  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(false);

  const redirectedPathName = (locale: string) => {
    if (!pathName) return '/home/en';
    const segments = pathName.split('/');
    segments[1] = locale;
    return segments.join('/');
  };

  return (
    <footer>
      <Container>
        <div className="d-flex align-items-center justify-content-between flex-wrap logo-wrapper" style={{ gap: 20 }}>
          <Image
            className="mt-3 "
            src={generalData?.footer_logo}
            alt="IranJourney"
            title="IranJourney"
            width={300}
            height={47}
            sizes="100vw"
            priority
          />

          <div className="d-flex align-items-center">
            <p className="mb-0 me-3"> {t('Keep in touch with us ')}</p>

            <ul className="list-unstyled p-0 mb-0 d-flex align-items-center justify-content-center" style={{ gap: 10 }}>
              <li>
                <a href={generalData.facebook_url} target="_blank" aria-label="Facebook" className="text-white">
                  <BsFacebook size={19} />
                </a>
              </li>
              <li>
                <a href={generalData.linkedin_url} target="_blank" aria-label="Linkedin" className="text-white">
                  <BsLinkedin size={19} />
                </a>
              </li>
              <li>
                <a href={generalData.instagram_url} target="_blank" aria-label="Instagram" className="text-white">
                  <BsInstagram size={19} />
                </a>
              </li>
              <li>
                <a href={generalData.youtube_url} target="_blank" aria-label="Youtube" className="text-white">
                  <BsYoutube size={19} />
                </a>
              </li>
              <li>
                <a href={generalData.tripadvisor_url} target="_blank" aria-label="Trip advisor" className="text-white">
                  <FaTripadvisor size={19} />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <hr className="hr-text2 mt-1" />
      </Container>

      <div className="d-lg-block d-none">
        <Container>
          <Row>
            <Col xs={3} className="text-center">
              <span className="fs-5">{t('Iran Visa ')}</span>
            </Col>
            <Col xs={3} className="text-center">
              <span className="fs-5">{t('Business Solutions ')}</span>
            </Col>
            <Col xs={3} className="text-center">
              <span className="fs-5"> {t('Contact us ')}</span>
            </Col>
            <Col xs={3} className="text-center">
              <span className="fs-5">{t('Resources ')}</span>
            </Col>
          </Row>
        </Container>

        {/* Collapse area */}
        <Collapse in={open}>
          <div id="footer-collapsed-contents">
            <Container>
              <Row>
                <Col xs={3} className="text-center">
                  <ul className="text-center p-0 list-unstyled">
                    <li className="pt-1">
                      <Link
                        className="text-decoration-none text-white"
                        href={`/${language}/iran-visa-for-british-american-canadian-passport-holders`}
                      >
                        {t('Visa for Americans ')}
                      </Link>
                    </li>
                    <li className="pt-1">
                      <Link
                        className="text-decoration-none text-white"
                        href={`/${language}/iran-visa-for-british-american-canadian-passport-holders`}
                      >
                        {t('Visa for British citizens ')}
                      </Link>
                    </li>
                    <li className="pt-1">
                      <Link
                        className="text-decoration-none text-white"
                        href={`/${language}/iran-visa-for-british-american-canadian-passport-holders`}
                      >
                        {t('Visa for Canadians ')}
                      </Link>
                    </li>
                    <li className="pt-1">
                      <Link className="text-decoration-none text-white" href={`/${language}/visa-application`}>
                        {t('Visa for other nationals ')}
                      </Link>
                    </li>
                  </ul>
                </Col>

                <Col xs={3}>
                  <ul className="text-center p-0 list-unstyled">
                    <li className="pt-1">
                      <Link className="text-decoration-none text-white" href={`/${language}/about-us`}>
                        {t('About us ')}
                      </Link>
                    </li>
                    <li className="pt-1">
                      <Link className="text-decoration-none text-white" href={`/${language}/business-solutions`}>
                        {t('12+1 B2B Solutions ')}
                      </Link>
                    </li>

                    <li className="pt-1">
                      <Link className="text-decoration-none text-white" href={`/${language}/worldwide-travel-agencies`}>
                        {t('Worldwide Travel Agencies ')}
                      </Link>
                    </li>
                    <li className="pt-1">
                      <Link className="text-decoration-none text-white" href={`/${language}/work-with-us`}>
                        {t('Worldwide Tour Leaders ')}
                      </Link>
                    </li>
                    <li className="pt-1">
                      <Link className="text-decoration-none text-white" href={`/${language}/our-milestones`}>
                        {t('Our Milestones ')}
                      </Link>
                    </li>
                  </ul>
                </Col>

                <Col xs={3}>
                  <ul className="text-center p-0 list-unstyled">
                    <li className="pt-1">
                      <Link className="text-decoration-none text-white" href={`/${language}/contact-us`}>
                        {t('Contact us ')}
                      </Link>
                    </li>
                    <li className="pt-1">
                      <Link className="text-decoration-none text-white" href={`/${language}/feedback`}>
                        {t('Submit Feedback ')}
                      </Link>
                    </li>

                    <li className="pt-1">
                      <Link className="text-decoration-none text-white" href={`/${language}/tailor-made-iran-tours`}>
                        {t('Customized Tour ')}
                      </Link>
                    </li>
                    <li className="pt-1">
                      <Link className="text-decoration-none text-white" href={`/${language}/work-with-us`}>
                        {t('Work with us ')}
                      </Link>
                    </li>
                  </ul>
                </Col>

                <Col xs={3}>
                  <ul className="text-center p-0 list-unstyled">
                    <li className="pt-1">
                      <Link className="text-decoration-none text-white" href={`/${language}/faq`}>
                        Help & customer support
                      </Link>
                    </li>
                    {/* <li className="pt-1">
                        <Link className='text-decoration-none' href={`${language}/Travellnsurance`}>
                          {t("Travel insurance ")}
                        </Link>
                      </li> */}
                    <li className="pt-1">
                      <Link
                        className="text-decoration-none text-white"
                        href={`/${language}/new-zealanders-travel-diary`}
                      >
                        {t('Iran Travel Diary ')}
                      </Link>
                    </li>
                    <li className="pt-1">
                      <Link className="text-decoration-none text-white" href={`/${language}/guest-books`}>
                        {t('Guest Book ')}
                      </Link>
                    </li>
                    <li className="pt-1">
                      <Link
                        className="text-decoration-none text-white"
                        href={`/${language}/credential-and-certificates`}
                      >
                        {t('Credential & Certificates ')}
                      </Link>
                    </li>
                    {/* <li className="pt-1">
                        <Link className='text-decoration-none' href={`${language}/SpecialThanks`}>
                          {t("Special Thanks ")}
                        </Link>
                      </li> */}
                  </ul>
                </Col>
              </Row>
            </Container>

            <Container>
              <hr className="hr-text2 d-none d-md-block" />
              <Row>
                <Col xs={12} md={6}>
                  <span className="fs-5">{t("We're at your service ")}</span>
                  <div className="mt-3 ">
                    <a
                      className="text-decoration-none text-white"
                      href={`https://api.whatsapp.com/send?phone=${generalData?.footer_whats_app_line}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>
                        <BsWhatsapp size={22} className="me-2" />
                        WhatsApp: {generalData?.footer_whats_app_line}
                      </p>
                    </a>
                  </div>
                  <div className="mt-3 ">
                    <CopyToClipboard
                      text={generalData?.footer_email}
                      onCopy={() => {
                        setCopied(true);
                        setTimeout(() => setCopied(false), 3000);
                      }}
                    >
                      <p>
                        <BsEnvelope size={22} className="me-2" />
                        {generalData?.footer_email}
                      </p>
                    </CopyToClipboard>
                    {copied ? (
                      <span
                        className="mt-5 px-2 px-1 bold"
                        style={{
                          background: '#ffcc00',
                          color: 'black',
                        }}
                      >
                        Copied!
                      </span>
                    ) : null}
                  </div>
                </Col>

                <Col md={6} xs={12}>
                  <span className="fs-5 text-center pl-4 ml-1 mb-3">{t('Shift to your language ')}</span>
                  <div className="country-switcher">
                    <ul className="list-unstyled p-0 m-0 d-flex flex-wrap align-items-center" style={{ gap: 10 }}>
                      {locales.map((country) => (
                        <li
                          key={country}
                          style={{
                            opacity: i18n.language === country ? '1' : '0.5',
                          }}
                        >
                          <Link className="text-decoration-none" href={redirectedPathName(country)}>
                            <Image
                              className="mr-2 image-responsive"
                              onClick={() => i18n.changeLanguage(country)}
                              alt={country}
                              src={`https://flagsapi.com/${
                                country === 'en' ? 'GB' : country.toUpperCase()
                              }/flat/64.png`}
                              width={80}
                              height={50}
                              style={{ objectFit: 'cover' }}
                              title={country}
                            />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
              <hr className="hr-text2 pb-0 " />
            </Container>

            {!isMobile && (
              <div className="mt-4 w-100 py-4 px-5 " style={{ background: 'white', color: 'black' }}>
                <div className="tw-flex">
                  <span className="tw-font-bold">{t('CREDENTIALS AND LICENSES')}:</span>
                  <div>
                    <p className={i18n.language == 'om' ? 'text-right' : ''}>
                      {t('A member of the Association of Air transport and Tourist agencies of Fars province.  ')}
                      <span className="tw-block">{t('A member of the Association of Iranian Tour Operators.')}</span>
                      <span className="tw-block">
                        {t(
                          'Licensed Travel agent and Tour Operator No. 972/133/14819 | Regulated by Iranian Cultural Heritage, Handicraft and Tourism Ministry. ',
                          { license: generalData?.license_number.toString() }
                        )}
                      </span>
                      <span className="tw-block">
                        {t('Certified at Technical Management Of Tourism and Travel Services')}
                      </span>
                    </p>
                  </div>
                </div>

                <p className={i18n.language === 'om' ? 'text-right' : ''}>
                  <span className="tw-font-bold tw-mr-1 ">{t('PAPER CONSUMPTION REDUCTION')}:</span>
                  {t(
                    'You do not have to supply your information in hard copies and our receipts and contracts will be online unless the hard copies are really required, or you prefer to.'
                  )}
                </p>

                <p className={i18n.language === 'om' ? 'text-right mt-2' : 'mt-2'}>
                  <span className="tw-font-bold tw-mr-1">{t('CONFIDENTIALITY STATEMENT ')}:</span>
                  {t(
                    "All details of the client's application are confidential and will be used only for the tour operation, consulate paperwork that is aligned with requested services. We may use this information to communicate with you about our Services, such as tours or events. The provided information and documents will be kept for future applications."
                  )}
                </p>

                <p className={i18n.language === 'om' ? 'text-right mt-2' : 'mt-2'}>
                  <span className="tw-font-bold tw-mr-1">{t('PRIVACY POLICY')}:</span>
                  {t(
                    'For information on the TIMNAK’s privacy policy, regarding the nature, purpose, use, and sharing of any personal information collected via this website please read the privacy page.'
                  )}
                </p>
              </div>
            )}

            <Container className="mb-3">
              <div className="d-none d-md-block">
                <FooterOtherWebsites />
                <hr className="hr-text2 pb-0 " />
                <Row className="text-center px-5 mx-5 ">
                  <Col xs={3} />

                  <Col xs={3} style={{ borderRight: '1px solid white' }}>
                    <Link className="text-decoration-none tw-text-white" href={`/${language}/privacy-and-policy`}>
                      {t('Privacy Policy')}
                    </Link>
                  </Col>

                  <Col xs={3}>
                    <Link className="text-decoration-none tw-text-white" href={`/${language}/terms-and-conditions`}>
                      {t('Terms & Conditions ')}
                    </Link>
                  </Col>
                  <Col xs={1}></Col>
                </Row>
              </div>
            </Container>
          </div>
        </Collapse>

        {/* Collapse btn control */}
        <Container className="pt-2 pb-4 text-center">
          <Row>
            <Col>
              <hr style={{ borderColor: 'white' }} />
            </Col>
            <Col md={1}>
              <Button
                className="rounded-circle p-2 cl-btn"
                variant="light"
                onClick={() => setOpen(!open)}
                style={{
                  minWidth: 42,
                  minHeight: 42,
                  maxWidth: 42,
                  maxHeight: 42,
                  width: 42,
                  height: 42,
                  boxShadow: 'none',
                  outline: 'none',
                }}
                title="Button to show footer links"
              >
                {open ? <BsChevronUp size={22} /> : <BsChevronDown size={22} />}
              </Button>
            </Col>
            <Col>
              <hr style={{ borderColor: 'white' }} />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="d-lg-none d-block pb-5">
        <Container>
          <Accordion>
            <Accordion.Item eventKey="0" className="tw-w-full tw-bg-transparent tw-border-none">
              <Accordion.Header as={'span'} className="tw-bg-transparent fs-2">
                <span className="text-center">{t('Iran Visa ')}</span>
                <BsChevronDown size={22} className="tw-ml-auto" />
              </Accordion.Header>
              <Accordion.Body>
                <ul className="p-0 list-unstyled">
                  <li className="pt-1">
                    <Link
                      className="text-decoration-none"
                      href={`/${language}/iran-visa-for-british-american-canadian-passport-holders`}
                    >
                      {t('Visa for Americans ')}
                    </Link>
                  </li>
                  <li className="pt-1">
                    <Link
                      className="text-decoration-none"
                      href={`/${language}/iran-visa-for-british-american-canadian-passport-holders`}
                    >
                      {t('Visa for British citizens ')}
                    </Link>
                  </li>
                  <li className="pt-1">
                    <Link
                      className="text-decoration-none"
                      href={`/${language}/iran-visa-for-british-american-canadian-passport-holders`}
                    >
                      {t('Visa for Canadians ')}
                    </Link>
                  </li>
                  <li className="pt-1">
                    <Link className="text-decoration-none" href={`/${language}/visa-application`}>
                      {t('Visa for other nationals ')}
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" className="tw-w-full tw-bg-transparent tw-border-none">
              <Accordion.Header as={'span'} className="tw-bg-transparent fs-2">
                <span>{t('Business Solutions ')}</span>
                <BsChevronDown size={22} className="tw-ml-auto" />
              </Accordion.Header>
              <Accordion.Body>
                <ul className="p-0 list-unstyled">
                  <li className="pt-1">
                    <Link className="text-decoration-none" href={`/${language}/about-us`}>
                      {t('About us ')}
                    </Link>
                  </li>
                  <li className="pt-1">
                    <Link className="text-decoration-none" href={`/${language}/business-solutions`}>
                      {t('12+1 B2B Solutions ')}
                    </Link>
                  </li>

                  <li className="pt-1">
                    <Link className="text-decoration-none" href={`/${language}/worldwide-travel-agencies`}>
                      {t('Worldwide Travel Agencies ')}
                    </Link>
                  </li>
                  <li className="pt-1">
                    <Link className="text-decoration-none" href={`/${language}/work-with-us`}>
                      {t('Worldwide Tour Leaders ')}
                    </Link>
                  </li>
                  <li className="pt-1">
                    <Link className="text-decoration-none" href={`/${language}/our-milestones`}>
                      {t('Our Milestones ')}
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" className="tw-w-full tw-bg-transparent tw-border-none">
              <Accordion.Header as={'span'} className="tw-bg-transparent fs-2">
                <span> {t('Contact us ')}</span>
                <BsChevronDown size={22} className="tw-ml-auto" />
              </Accordion.Header>
              <Accordion.Body>
                <ul className="p-0 list-unstyled">
                  <li className="pt-1">
                    <Link className="text-decoration-none" href={`/${language}/contact-us`}>
                      {t('Contact us ')}
                    </Link>
                  </li>
                  <li className="pt-1">
                    <Link className="text-decoration-none" href={`/${language}/feedback`}>
                      {t('Submit Feedback ')}
                    </Link>
                  </li>

                  <li className="pt-1">
                    <Link className="text-decoration-none" href={`/${language}/tailor-made-iran-tours`}>
                      {t('Customized Tour ')}
                    </Link>
                  </li>
                  <li className="pt-1">
                    <Link className="text-decoration-none" href={`/${language}/work-with-us`}>
                      {t('Work with us ')}
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3" className="tw-w-full tw-bg-transparent tw-border-none">
              <Accordion.Header as={'span'} className="tw-bg-transparent fs-2">
                <span>{t('Resources ')}</span>
                <BsChevronDown size={22} className="tw-ml-auto" />
              </Accordion.Header>
              <Accordion.Body>
                <ul className="p-0 list-unstyled">
                  <li className="pt-1">
                    <Link className="text-decoration-none" href={`/${language}/faq`}>
                      Help & customer support
                    </Link>
                  </li>

                  <li className="pt-1">
                    <Link className="text-decoration-none" href={`/${language}/new-zealanders-travel-diary`}>
                      {t('Iran Travel Diary ')}
                    </Link>
                  </li>
                  <li className="pt-1">
                    <Link className="text-decoration-none" href={`/${language}/guest-books`}>
                      {t('Guest Book ')}
                    </Link>
                  </li>
                  <li className="pt-1">
                    <Link className="text-decoration-none" href={`/${language}/credential-and-certificates`}>
                      {t('Credential & Certificates ')}
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="text-center mb-4 mt-5">
            <span className="fs-5">{t("We're at your service ")}</span>
            <div className="mt-3">
              <a
                className="text-decoration-none text-white"
                href="https://api.whatsapp.com/send?phone=+989173024521"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>
                  <BsWhatsapp size={22} className="me-2" />
                  WhatsApp: {generalData?.footer_whats_app_line}
                </p>
              </a>
            </div>
            <div className="mt-3">
              <CopyToClipboard
                text={generalData?.footer_email}
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => setCopied(false), 3000);
                }}
              >
                <p>
                  <BsEnvelope size={22} className="me-2" />
                  {generalData?.footer_email}
                </p>
              </CopyToClipboard>
              {copied ? (
                <span
                  className="mt-5 px-2 px-1 bold"
                  style={{
                    background: '#ffcc00',
                    color: 'black',
                  }}
                >
                  Copied!
                </span>
              ) : null}
            </div>
          </div>
        </Container>
      </div>

      <div className="tw-bg-gray-600">
        <Container>
          <div className="tw-flex tw-items-center tw-justify-evenly">
            <span>{generalData.end_line_1}</span>

            <span className="d-none d-md-block">{generalData.end_line_2}</span>

            <span>{generalData.end_line_3}</span>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
