import React from 'react';

const ApiFailed = ({ refetch }: { refetch: VoidFunction }) => {
  return (
    <div className="shared-api-failure tw-min-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center">
      <span className="lalezar text-sm text-gray-800">Ops! Something went wrong. Please try again.</span>
      <button onClick={refetch} className="btn btn-link tw-text-sm tw-text-blue-500 tw-underline tw-mt-3">
        Retry
      </button>
    </div>
  );
};

export default ApiFailed;
