'use client';

import React, { useContext, useEffect, useState } from 'react';
import Contexts from '@/contexts';
import moment from 'moment';
import { useWindowScroll } from '@uidotdev/usehooks';
import { Alert, Button, Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { BsFacebook, BsInstagram, BsLinkedin, BsX, BsYoutube } from 'react-icons/bs';
import { FaTripadvisor } from 'react-icons/fa';
import Link from 'next/link';
import { useTranslation } from '@/i18n/client';
import { Locale } from '../../../i18n-config';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { locales } from '@/i18n/settings';

const Header = ({ language }: { language: Locale }) => {
  const { t, i18n } = useTranslation(language);
  const pathName = usePathname();
  const sharedData = useContext(Contexts.sharedDataContext);
  const [{ y }] = useWindowScroll();

  const [emergencyAlert, setEmergencyAlert] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleCloseEmergencyAlert = () => {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.setItem('emergency_alert', moment().add(1, 'day').toString());

      setEmergencyAlert(false);
    }
  };

  const redirectedPathName = (locale: string) => {
    if (!pathName) return '/home/en';
    const segments = pathName.split('/');
    segments[1] = locale;
    return segments.join('/');
  };

  useEffect(() => {
    if (typeof window !== undefined && window.localStorage) {
      let condition = localStorage.getItem('emergency_alert');

      if (!condition && sharedData.emergency_note) setEmergencyAlert(true);
      else setEmergencyAlert(false);

      if (condition && moment(condition).isBefore(moment())) {
        localStorage.removeItem('emergency_alert');
        setEmergencyAlert(true);
      }
    }
  }, [sharedData.emergency_note]);

  return (
    <header
      className="layout-header"
      style={{
        backgroundColor: y && y > 0 ? 'rgba(255,255,255,0.9)' : 'white',
      }}
    >
      <Container>
        <div className="header-menu">
          {emergencyAlert && (
            <Alert
              variant=""
              className={`text-center mb-0 d-flex align-items-center justify-content-between ${
                y && y > 0 ? 'mb-3' : 'mb-1'
              } pt-0 pb-0 pr-0 pl-1`}
              style={{
                backgroundColor: sharedData.color_code ? `#${sharedData.color_code}` : '',
                color: sharedData.text_color_code ? sharedData.text_color_code : '',
                fontSize: 12,
              }}
            >
              {sharedData.emergency_note}
              <Button variant="" className="p-0 ms-2" onClick={handleCloseEmergencyAlert}>
                <BsX size={28} />
              </Button>
            </Alert>
          )}
          {/* Top bar */}
          {(y as number) <= 0 && (
            <div className="d-none d-lg-block">
              <div className="d-flex align-items-center justify-content-between inquire-section tw-min-h-[24px]">
                <Link className="text-small tw-font-bold" href={`/${language}/tailor-made-iran-tours`}>
                  {t('Inquire Now ')}
                </Link>
                <ul className="list-unstyled d-flex align-items-center p-0 m-0" style={{ gap: 10 }}>
                  <li>
                    <a href={sharedData.facebook_url} target="_blank" aria-label="Facebook">
                      <BsFacebook />
                    </a>
                  </li>
                  <li>
                    <a href={sharedData.linkedin_url} target="_blank" aria-label="Linkedin">
                      <BsLinkedin />
                    </a>
                  </li>
                  <li>
                    <a href={sharedData.instagram_url} target="_blank" aria-label="Instagram">
                      <BsInstagram />
                    </a>
                  </li>
                  <li>
                    <a href={sharedData.youtube_url} target="_blank" aria-label="YouTube">
                      <BsYoutube />
                    </a>
                  </li>
                  <li>
                    <a href={sharedData.tripadvisor_url} target="_blank" aria-label="Trip advisor">
                      <FaTripadvisor />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {/* Main menu */}
          <div className="row">
            <div className="col-12">
              <Navbar collapseOnSelect={true} expand="lg" expanded={expanded} className="py-md-0 py-2">
                <Link href={`/`} className={`navbar-brand py-sm-0 ${(y as number) > 0 ? 'is-scrolled' : ''}`}>
                  <Image
                    alt="IranJourney"
                    className="img-fluid"
                    src={sharedData.header_logo}
                    width={440}
                    height={80}
                    title="IranJourney logo"
                    priority={true}
                  />
                </Link>
                <Navbar.Toggle aria-controls="navbarSupportedContent" onClick={() => setExpanded(!expanded)} />

                <Navbar.Collapse id="navbarSupportedContent">
                  {/* Call expert */}
                  {(y as number) > 20 && (
                    <div
                      className="d-flex align-items-center justify-content-between flex-wrap py-sm-0 py-3 me-auto"
                      style={{ gap: 20 }}
                    >
                      <a href="tel:+989173024521" className="tw-no-underline">
                        <small className="text-dark bold">Call the experts! </small>
                        <small className="text-timnak">{sharedData.header_whats_app_line}</small>
                      </a>
                    </div>
                  )}

                  <Nav className="ms-auto py-4 py-md-0">
                    <NavDropdown
                      title={t('Travel Styles ')}
                      className="pl-4 pl-md-0 ml-0 ml-md-4"
                      placement="auto"
                      as={'span'}
                    >
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/tours?style=Group_Tours`}>
                          {t('Group Tours ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/tours?style=FamilyJourneys`}>
                          {t('Family Journeys ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/tours?style=Seniors-Classic`}>
                          {t('Seniors - Classic ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/tours?style=NatureAdventure`}>
                          {t('Nature Adventure ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/tours?style=Hiking&Trekking`}>
                          {t('Hiking & Trekking ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/tours?style=RailJourneys`}>
                          {t('Rail Journeys ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/tours?style=Nomads`}>
                          {t('Nomads ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/tours?style=ski`}>
                          {t('Ski & Snow boarding ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/tours?style=Photography`}>
                          {t('Photography ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/tours?style=HealthTourism`}>
                          {t('Health Tourism ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/tours?style=Student_tours_to_IRAN`}>
                          {t('Student tours')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/tours?style=Low budget offers`}>
                          {t('Low budget offers')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/diplomatic-tours`}>
                          {t('Diplomatic tours ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/tailor-made-iran-tours`}>
                          {t('Customizing my trip ')}
                        </Link>
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title={t('Guide book ')} className="pl-4 pl-md-4 ml-0 ml-md-4" as={'span'}>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/new-zealanders-travel-diary`}>
                          {t('Iran travel diary ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/guest-books`}>
                          {t('Guest book ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/articles-and-news`}>
                          {t('Articles and news ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/travel-styles`}>
                          {t('Travel Styles ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/health-packing-list-for-iran`}>
                          {t('Health packing list ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/gallery`}>
                          {t('Image Gallery ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/us-sanctions`}>
                          {t('US Sanction ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/travel-cost-estimation`}>
                          {t('Travel Cost Estimation ')}
                        </Link>{' '}
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/illegal-tour-organizers`}>
                          {t('Illegal tour organizers')}
                        </Link>
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title={t('Find out more ')} className="nav-item pl-4 pl-md-4 ml-0 ml-md-4" as={'span'}>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/about-us`}>
                          {t('About us ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/contact-us`}>
                          {t('Contact us ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/tailor-made-iran-tours`}>
                          {t('Customizing a trip ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/work-with-us`}>
                          {t('Work with us ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/worldwide-travel-agencies`}>
                          {t('Travel Agencies ')}
                        </Link>{' '}
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/our-fleet`}>
                          {t('Our fleet')}
                        </Link>{' '}
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/credential-and-certificates`}>
                          {t('Credential & Certificates ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/our-milestones`}>
                          {t('Our Milestones ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/visa-for-visiting-iran`}>
                          {t('Iran visa application form ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/travel-insurance`}>
                          {t('Travel Insurance + Emergency plug in ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/sustainable-travel`}>
                          {t('Sustainable Tourism ')}
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item as={'span'} onClick={() => setExpanded(false)}>
                        <Link className="tw-block" href={`/${language}/female-travelers`}>
                          {t('Female Travelers')}
                        </Link>
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title={language.toUpperCase()} as={'span'}>
                      {locales.map((country) => (
                        <NavDropdown.Item as={'span'} key={country} className="tw-inline-flex tw-gap-2 tw-w-auto">
                          <Link className="text-decoration-none" href={redirectedPathName(country)}>
                            <Image
                              className="mr-2 image-responsive"
                              onClick={() => i18n.changeLanguage(country)}
                              alt={country}
                              src={`https://flagsapi.com/${
                                country === 'en' ? 'GB' : country.toUpperCase()
                              }/flat/64.png`}
                              width={40}
                              height={30}
                              style={{ objectFit: 'cover' }}
                              title={country}
                            />
                          </Link>
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
