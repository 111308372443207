'use client';

import React from 'react';
import contexts from '../contexts/index';
import { useQuery } from 'react-query';
import Loading from '@/components/shared/Loading';
import ApiFailed from '@/components/shared/ApiFailed';

const SharedDataProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoading, isError, isSuccess, data, refetch } = useQuery('global-config', () =>
    fetch('https://api.iranjourney.com/api/pageconfig/home_page_config_get').then((res) => res.json())
  );

  if (isLoading) return <Loading />;

  if (isError) return <ApiFailed refetch={refetch} />;

  if (isSuccess)
    return <contexts.sharedDataContext.Provider value={data}>{children}</contexts.sharedDataContext.Provider>;
};

export default SharedDataProvider;
