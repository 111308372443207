import React from 'react';
import { SharedDataType } from './types';

const initialState: SharedDataType = {
  '1': {
    url1: '',
    card1: '',
  },
  '2': {
    url2: '',
    card2: '',
  },
  '3': {
    url3: '',
    card3: '',
  },
  '4': {
    url4: '',
    card4: '',
  },
  '5': {
    url5: '',
    card5: '',
  },
  '6': {
    url6: '',
    card6: '',
  },
  id: 0,
  header_whats_app_line: '',
  footer_whats_app_line: '',
  footer_email: '',
  license_number: '',
  header_logo: '',
  footer_logo: '',
  video_url: '',
  emergency_note: '',
  expiry_date_emergency: '',
  color_code: '',
  text_color_code: '',
  subscribe_img: '',
  records_day_tour: 0,
  records_application: 0,
  records_passenger: 0,
  records_nationality: 0,
  contact_us_banner: '',
  facebook_url: '',
  linkedin_url: '',
  instagram_url: '',
  tripadvisor_url: '',
  youtube_url: '',
  end_line_1: '',
  end_line_2: '',
  end_line_3: '',
  travel_cost_image: '',
  travel_cost_title: '',
  iran_vis_image: '',
  iran_visa_title: '',
  special_visa_image: '',
  special_visa_title: '',
  customize_my_trip_image: '',
  customize_my_trip_title: '',
};

const sharedDataContext = React.createContext(initialState);

const Contexts = {
  sharedDataContext,
};

export default Contexts;
