import React from 'react';

interface ICopyToClipboard {
  text: string;
  onCopy: () => void;
  children: React.ReactNode;
}

const CopyToClipboard = ({ text, onCopy, children }: ICopyToClipboard) => {
  const handleClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      onCopy();
    }
  };

  return (
    <React.Fragment>
      {React.cloneElement(children as React.ReactElement, {
        onClick: handleClick,
      })}
    </React.Fragment>
  );
};

export default CopyToClipboard;
