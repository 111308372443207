import type { InitOptions } from 'i18next';

export const fallbackLng = 'en';
export const locales = [
  fallbackLng,
  'fr',
  'br',
  'jp',
  'hk',
  'cn',
  'ru',
  'pt',
  'it',
  'tr',
  'om',
  'de',
  'nl',
  'es',
] as const;
export type LocaleTypes = (typeof locales)[number];

export function getOptions(lang = fallbackLng): InitOptions {
  return {
    //debug: true, // Set to true to see console logs
    supportedLngs: locales,
    fallbackLng,
    lng: lang,
    react: { useSuspense: true },
    nonExplicitSupportedLngs: true,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  };
}
